import { event } from 'jquery';
import React from 'react';
import { connect } from 'react-redux';
import { analyticsClickEvent } from '../../../action/firebaseAnalytics/firebaseAnalytics';
import { GTMFieldInteraction } from '../../../utils/GtmDatalayer';
import Cookies from 'universal-cookie';
import { extractEmail } from '../../../utils/extractEmail';
import { PropTypes } from 'prop-types';
import { frontEndLogs } from '../../../action/logs/frontEndLogs';
import { handleAddressFiledValidation } from '../../../utils/helpers/addressFieldValidation';

function checkApplicationRequiredFieldInProps(allDetails,prevState,allDetailData,nextProps,showDev){
  if (allDetails[prevState.fieldname] === '' && allDetailData[prevState.fieldname] === '') {
    let stages = {}
    stages[prevState.fieldname] = 'This is Required';
    stages['stage'] = nextProps.stage;
    // nextProps.appvalidations(stages);
    let inputValues = {}
    inputValues[prevState.fieldname] = '';
    //nextProps.saveApplicationDatas(inputValues)
    return {
      showDev,
      value: ''
    };
  }
}

function checkResidentialStatusValidation(allDetailData,allDetails,nextProps){
  if (allDetailData['Current_Residential_Status__c'] === 'Homeowner - no mortgage' || allDetailData['Current_Residential_Status__c'] === 'Living with parents/family') {
    if (allDetails['Fractional_Residential_Expense__c'] == 'Amount cannot be £0 if your Residential status is Homeowner - mortgaged or Tenant/Boarding' || allDetails['Fractional_Residential_Expense__c'] =='This is Required') {
      let stagesValues = {}
      stagesValues['Fractional_Residential_Expense__c'] = '';
      stagesValues['stage'] = nextProps.stage;
      nextProps.appvalidations(stagesValues);
      return {
        value: ''
      };
    }
  }
}

function applicationQuestionFieldValidationBasedOnPriority(nextProps,prevState){
  if (nextProps.amountRequested.split(',').join('').length > 0 || nextProps.amountRequested === '') {
    if (nextProps.priority === 3) {
      let showDev = prevState.showDev;
      showDev[prevState.fieldname] = true;
      const allDetails = Object.assign({}, ...nextProps.applicationValidation);
      const allDetailData = Object.assign({}, ...nextProps.saveApplicationData);
      //for Fractional_Residential_Expense__c validations
      let resultResidentialStatus = checkResidentialStatusValidation(allDetailData,allDetails,nextProps);
      if(resultResidentialStatus){
        return resultResidentialStatus;
       }

     let checkVerifiedResult =  checkApplicationRequiredFieldInProps(allDetails,prevState,allDetailData,nextProps,showDev);
     if(checkVerifiedResult){
      return checkVerifiedResult;
     }
    }
  }
}

function checkSortCodePropsValidation(nextProps,prevState){
  if (nextProps.prepopulateCondition === true) {
    const allDetailDatas = Object.assign({}, ...nextProps.saveApplicationData);
    if (allDetailDatas[prevState.fieldname] && prevState.prepopulateUpdate === true) {

      if (allDetailDatas[prevState.fieldname] != prevState.value) {
        let preValue = allDetailDatas[prevState.fieldname];
        if (prevState.fieldname === 'sortcode_bankaccount') {
          preValue = allDetailDatas[prevState.fieldname].replace(/(\d{2})(\d{2})(\d{2})/, "$1-$2-$3")
        }

        return {
          value: preValue,
          prepopulateUpdate: false
        };
      }
    }

  }
}

export class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      req: {},
      errors: {
      },

      value: '',
      displayerrors: '',
      inputType: 'text',
      showValidation: this.props.showAppValidations,
      stages: this.props.stage,
      curvalue: '',
      showDevs: true,
      prepopulateUpdate: true,
      keyData: '',
      showDev: {

      },
      showInformation : '', 
      maxValue: '',
      fieldname: this.props.field_api_name,
      hideFieldList: []
    }
    this.inputRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidMount() {
    const cookie = new Cookies()
    let questionValues = {};
    questionValues['field_api_name'] = this.props.field_api_name;
    questionValues['priority'] = this.props.priority;
    questionValues['stage'] = this.props.stage;
    questionValues['field_question'] = this.props.field_question;
    
    if (this.props.field_api_validations.length != 0) {
      questionValues['validation'] = 'yes';
    } else {
      questionValues['validation'] = 'no';
    }
    this.props.questionSave(questionValues);
   

    if (this.props.field_type === 'Number') {
      this.setState({ inputType: 'number' })
    }
    if (this.props.field_type === 'Email') {
      this.setState({ inputType: 'email' })
    }
    if (this.props.field_type === 'Currency' || this.props.field_type === 'Text' || this.props.field_api_name === 'sortcode_bankaccount') {
      this.setState({ inputType: 'text' })
    }
    let input = this.state.input;
    input[this.props.field_api_name] = '';
    this.setState({
      input
    });
    if (this.props.priority === 3) {
      let showDev = this.state.showDev;
      showDev[this.props.field_api_name] = false;
      let stagesVal = {};
      stagesVal[this.props.field_api_name] = '';
      stagesVal['stage'] = this.props.stage;
      this.props.appvalidations(stagesVal);
      this.setState({
        showDev
      });
    } else {
      let showDev = this.state.showDev;
      showDev[this.props.field_api_name] = true;
      this.setState({
        showDev
      });
    }
    if (this.props.field_api_name === 'genesis__Loan_Amount__c') {
      this.props.threasholdFunc(this.props.threshold);
      this.setMaximumThresholdLimitForLoanAmount()
      this.setMaximumThresholdLimitForQuoteLoanAmount();
      let fieldValues = {}, stageValues = {};
      let purchaseTrackingId = window.location.href.includes('trackingId');
      if (cookie.get('trackingId')) {
        purchaseTrackingId = cookie.get('trackingId')
      }  
      if(purchaseTrackingId && cookie.get('loanAmount') !== '' && cookie.get('loanAmount') !== undefined){
        const allDetailData = Object.assign({}, ...this.props.saveApplicationData);
        let formatedLoanAmount = this.handleLoanAmount(cookie.get('loanAmount'));
          allDetailData[this.props.field_api_name] = formatedLoanAmount;
          this.setState({value: allDetailData[this.props.field_api_name]});
          this.props.saveApplicationDatas(allDetailData);
          this.props.getAmountRequested(formatedLoanAmount)
        setTimeout(() => {
          this.checkLoanAmountFieldvalidationInpurchasejourney();
        }, 500);
      }
      else if(!purchaseTrackingId){
      this.setQuoteLoanAmountInApplication();
      }
    }
    
    this.prepopulateFirstNameAndLastName();
    this.prepopulateEmailAndMobile()

    this.setMinimumThresholdLimitForIncome();

      this.validationForUserDetailsPrePopulatedData();

  }

  // To check show the loan amount validation message
  checkLoanAmountFieldvalidationInpurchasejourney = () => {
    try {
      const cookie = new Cookies();
      let fieldValues = {}, stageValues = {};
      if (!this.checkValueBeforePrePopulate('genesis__Loan_Amount__c', cookie.get('loanAmount'))) {
        if (this.props.field_api_validations !== '') {
          frontEndLogs('Loan amount field validation', false, 200, 'checkLoanAmountFieldvalidationInpurchasejourney', this.props.field_api_validations);
          const allUserData = Object.assign({}, ...this.props.saveApplicationData);
          this.props.field_api_validations.map((src) => {
            if (src.flxType__c === 'Contains') {
              let errorMessage = src.flxError_Message__c;
              if (errorMessage.includes('LOAN_AMOUNT')) {
                errorMessage = errorMessage.replace('LOAN_AMOUNT', allUserData['genesis__Loan_Amount__c'])
              }
              fieldValues['genesis__Loan_Amount__c'] = errorMessage;
              stageValues['genesis__Loan_Amount__c'] = false;
              this.props.appvalidations(fieldValues);
              this.props.secondSectionValidationUpdate(stageValues)
            }
          })
        }
      } else {
        fieldValues['genesis__Loan_Amount__c'] = '';
        stageValues['genesis__Loan_Amount__c'] = true;

        this.props.appvalidations(fieldValues);
        this.props.secondSectionValidationUpdate(stageValues)
      }
    } catch (error) {
      frontEndLogs('Catch Error', true, 400, 'checkLoanAmountFieldvalidationInpurchasejourney', error.message);
    }
  }


  setQuoteLoanAmountInApplication = ()=>{
    let loanAmountFieldValues = {}, loanAmountstageValues = {};
      if(localStorage.getItem('quote-loan-amount')){
        const allApplicationDetailData = Object.assign({}, ...this.props.saveApplicationData);
        let formatedQuoteLoanAmount = this.handleLoanAmount(localStorage.getItem('quote-loan-amount'));
        if(this.checkValueBeforePrePopulate('genesis__Loan_Amount__c',localStorage.getItem('quote-loan-amount'))){
          allApplicationDetailData[this.props.field_api_name] = formatedQuoteLoanAmount;
          this.setState({value: allApplicationDetailData[this.props.field_api_name]});
          this.props.saveApplicationDatas(allApplicationDetailData);
          this.props.getAmountRequested(formatedQuoteLoanAmount)

          loanAmountFieldValues['genesis__Loan_Amount__c'] = '';
          loanAmountstageValues['genesis__Loan_Amount__c'] = true;

          this.props.appvalidations(loanAmountFieldValues);
          this.props.secondSectionValidationUpdate(loanAmountstageValues)
        }
      }
  }


  validationForUserDetailsPrePopulatedData = () => {
    let fieldValues = {}, stageValues= {}
    let allUserDataDetails = Object.assign({}, ...this.props.saveApplicationData);

    if((!this.props.editable && this.props.prepopulateStatus) || this.props.firstNameFlag) {
      fieldValues['First_Name'] = '';
      stageValues['First_Name'] = true;
    } 
    if((!this.props.editable && this.props.prepopulateStatus) || this.props.lastNameFlag) {
      fieldValues['Last_Name'] = '';
      stageValues['Last_Name'] = true;
    }
    if((!this.props.editable && this.props.prepopulateStatus) || this.props.emailFlag) {
      fieldValues['Email_Address'] = '';
      stageValues['Email_Address'] = true;
    }
    if((!this.props.editable && this.props.prepopulateStatus) || 
    this.props.mobileNumberFlag) {
      fieldValues['Mobile_Number'] = '';
      stageValues['Mobile_Number'] = true;
    }

    this.props.appvalidations(fieldValues);
    this.props.firstSectionValidationUpdate(stageValues)

    setTimeout(() => {
      if(allUserDataDetails['First_Name'] && (allUserDataDetails['First_Name'] == '' || allUserDataDetails['First_Name'] == null || allUserDataDetails['First_Name'] == undefined)){
        fieldValues['First_Name'] = 'This is Required';
        stageValues['First_Name'] = false;

      }
    }, 400);
  }

  prepopulateFirstNameAndLastName = async ()=> {
    const cookie = new Cookies();
    let fieldValues = {}, stageValue = {};

    if (this.props.field_api_name === 'First_Name') {
      if(cookie.get('firstName')){
        const allUserDetailData = Object.assign({}, ...this.props.saveApplicationData);

        if(allUserDetailData['First_Name'] === undefined && this.checkValueBeforePrePopulate('First_Name',cookie.get('firstName'))){
          allUserDetailData[this.props.field_api_name] = cookie.get('firstName');
          await this.props.saveApplicationDatas(allUserDetailData);

          fieldValues['First_Name'] = '';
          stageValue['First_Name'] = true;

          this.props.appvalidations(fieldValues);
          this.props.firstSectionValidationUpdate(stageValue)
        }
      }
    }

    if (this.props.field_api_name === 'Last_Name') {
      if(cookie.get('lastName') !== '' && cookie.get('lastName') !== undefined){
        const allDetailData = Object.assign({}, ...this.props.saveApplicationData);

        if(allDetailData['Last_Name'] === undefined &&this.checkValueBeforePrePopulate('Last_Name',cookie.get('lastName'))){
          allDetailData[this.props.field_api_name] = cookie.get('lastName');
          this.props.saveApplicationDatas(allDetailData);

          fieldValues['Last_Name'] = '';
          stageValue['Last_Name'] = true;

          this.props.appvalidations(fieldValues);
          this.props.firstSectionValidationUpdate(stageValue)
        }
      }
    }
  }

  prepopulateEmailAndMobile = ()=> {
    const cookie = new Cookies();
    let fieldValues = {}, stageValue = {};

    if (this.props.field_api_name === 'Email_Address') {
      if(cookie.get('email')){
        const allUserDetails = Object.assign({}, ...this.props.saveApplicationData);

        if(allUserDetails['Email_Address'] === undefined && this.checkValueBeforePrePopulate('Email_Address',cookie.get('email')) && this.validateEmail(cookie.get('email'))){
          allUserDetails[this.props.field_api_name] = cookie.get('email');
          this.props.saveApplicationDatas(allUserDetails);

          fieldValues['Email_Address'] = '';
          stageValue['Email_Address'] = true;

          this.props.appvalidations(fieldValues);
          this.props.firstSectionValidationUpdate(stageValue)
        }
      }
    }

    if (this.props.field_api_name === 'Mobile_Number') {
      if(cookie.get('mobileNumber') !== '' && cookie.get('mobileNumber') !== undefined){
        const allUserData = Object.assign({}, ...this.props.saveApplicationData);

        if(allUserData['Mobile_Number'] == undefined  && this.checkValueBeforePrePopulate('Mobile_Number',cookie.get('mobileNumber'))){
          allUserData[this.props.field_api_name] = cookie.get('mobileNumber');
          this.props.saveApplicationDatas(allUserData);

          fieldValues['Mobile_Number'] = '';
          stageValue['Mobile_Number'] = true;

          this.props.appvalidations(fieldValues);
          this.props.firstSectionValidationUpdate(stageValue);
        }
      }
    }
  }

  checkValueBeforePrePopulate = (fieldName, value) => {
    switch(fieldName) {
      case 'First_Name':
        {
          const basicTitleRegex = /^(mr|ms|mrs|miss|dr|prof|rev|hon|sir|dame|lord|lady|capt|major|col|gen|lt|sgt|fr|rabbi|imam)\b/i;
          const newTitleRegex = /^(bishop|cardinal|baron|baroness|viscount|viscountess|marquess|marchioness|earl|countess|duchess|prince|king)\b/i;
          const titleRegex = new RegExp(`${basicTitleRegex.source}|${newTitleRegex.source}`, 'i');
          return (value.length >= 2 && /^[a-zA-Z\s]+$/.test(value) && value.length <= 30 && !titleRegex.test(value));
        }
      case 'Last_Name':
        return (value.length >= 2 && /^[a-zA-Z\s]+$/.test(value) && value.length <= 30);
      case 'Email_Address':
        return ((extractEmail(value) != null) && value.length >= 1 && value.length <= 49);
      case 'Mobile_Number':
        return this.validationForMobileNumber(value);
      case 'genesis__Loan_Amount__c':
        if(localStorage.getItem('maxLoanAmountRange') !== null && localStorage.getItem('maxLoanAmountRange') !== '' &&
        localStorage.getItem('minLoanAmountRange') !== null && localStorage.getItem('minLoanAmountRange') !== '')
        {
          let maxLoanAmountRange = parseInt(localStorage.getItem('maxLoanAmountRange'))
          let minLoanAmountRange = parseInt(localStorage.getItem('minLoanAmountRange'))

          return (value >= minLoanAmountRange && value <= maxLoanAmountRange);
        }
    }
  }

  validationForMobileNumber = (value) => {
    if (value.length !== 11) {
      return false;
    } else if (!/^\d+$/.test(value)) {
      return false;
    } else if (value.substring(0, 2) !== '07') {
      return false;
    } else {
      return true;
    }
  }

  handleLoanAmount = (value) => {
    let finalValue;

    if (value.length !== 0) {
      if(value.toString().indexOf(".") >= 0){
        let decimalPosition = value.toString().indexOf(".");
        let leftSide = value.toString().substring(0, decimalPosition);
        let rightSide = value.toString().substring(decimalPosition);
        let replaceLeftSide=leftSide.replace(/\D/g, "").split(',').join('');
        rightSide = rightSide.substring(0, 3);
        if(rightSide.length===2){
          rightSide=rightSide+'0';
        }

      if(rightSide.length===1){
        rightSide='.00';
      }
      value=replaceLeftSide+rightSide;
      finalValue = value;

      }else{
        value = value.toString().replace(/\D/g, "").split(',').join('')+'.00';
        finalValue = value;
      }
    }

    return finalValue;
  }

  setMinimumThresholdLimitForIncome = () => {
    const fieldName = 'Total_Monthly_Income__c'

    if(this.props.field_api_name === fieldName){
      if (this.props.field_api_validations !== '') {
        this.props.field_api_validations.map((src) => {
          if (src.flxType__c === 'Minimum Range') {
            localStorage.setItem('minMonthlyIncome', src.flxMin_Value__c)
          }
        })
      }
    }
  }

  handleFocus(field){
    switch(field){
        case "First_Name":
        case "Last_Name":
        case "Mobile_Number":
        case "Email_Address":
        case "flxFirst_Name__c":
        case "flxLast_Name__c":
        case "genesis__Loan_Amount__c":
        case "frequency":
        case "Loan_Term_Requested__c":
        case "Current_Address_Country__c":
        case "Address_Line1__c":
        case "Address_Line2__c":
        case "Town__c":
        case "Postal_Code__c":
        case "Current_Residential_Status__c":
        case "Previous_Address_Country__c":
        case "Previous_Address_Line1__c":
        case "Previous_Address_Line2__c":
        case "PreviousTown__c":
        case "PreviousPostal_Code__c":
        case "Total_Monthly_Income__c":
        case "Fractional_Residential_Expense__c":
        case "monthly_expense_financial_commitments":
        case "Fractional_Living_Expense__c":
        case "Total_Monthly_Expenses__c":
        case "flxNumber_of_Other_Financial_Dependents__c":
        case "Employment_Stability__c":
        case "occupation":
        case "sortcode_bankaccount":
        case "genesis__bank_account_number__c":
            GTMFieldInteraction(this.props.field_label);
            break;
    }
  }

  onFocus = (e) =>{
    this.handleFocus(e.target.name);
    if (e.target.name === "sortcode_bankaccount" || e.target.name === "occupation") {
      e.target.setAttribute('autoComplete', 'off');
    } else {
      e.target.setAttribute('autoComplete', 'nope');
    }
  }




  static getDerivedStateFromProps(nextProps, prevState) {
    if(nextProps.firstSectionSteps) {
      const inputElement = document.getElementById('Mobile_Number');
      if(nextProps.isChangeMobile) {
        if (inputElement) {
          inputElement.focus();
        }
      }

      if(nextProps.isChangeEmail){
      const inputEmailElement = document.getElementById('Email_Address');
      if(nextProps.isChangeEmail && inputEmailElement) {
        inputEmailElement.focus();
      }
    }
    }

    if (prevState.value !== nextProps.field_value && nextProps.initialLoad === false) {
      return {
        value: nextProps.field_value
      };
    }

    if (nextProps.showAppValidations !== prevState.showValidation &&
        (nextProps.showAppValidations !== '' || nextProps.stage === '2')) {
      return {
        showValidation: nextProps.showAppValidations
      };
    }
    let checkNextPropsResult = applicationQuestionFieldValidationBasedOnPriority(nextProps,prevState);
    if(checkNextPropsResult){
      return checkNextPropsResult;
    }

    let checkSortCodePropsResult = checkSortCodePropsValidation(nextProps,prevState);
    if(checkSortCodePropsResult){
      return checkSortCodePropsResult;
    }
    return null;
  }

  handlRequiredFieldForNumberOfDependents = (src, errorValue, breakCondition) => {
    let stage = {};
    stage[this.props.field_api_name] = '';
    stage['stage'] = this.props.stage;
    if (src.flxType__c === 'Required Field') {
      if (this.state.input[this.props.field_api_name] === '') {
        errorValue[this.props.field_api_name] = 'This is Required';
        stage[this.props.field_api_name] = 'This is Required';
        stage['stage'] = this.props.stage;
        this.props.appvalidations(stage);
        this.setState({
          errors: errorValue
        });

        breakCondition = true;
      }
    }

    return breakCondition;
  }

  handleChangeForNumberOfDependents = (eventName) => {
    let breakCondition = false;
    let errorValue = this.state.errors;
    errorValue[this.props.field_api_name] = '';
    this.setState({
      errors: errorValue
    });
    let stage = {};
    stage[this.props.field_api_name] = '';
    stage['stage'] = this.props.stage;
    this.props.appvalidations(stage);
    if (this.props.field_api_validations !== '') {
      this.props.field_api_validations.map((src) => {
        if (this.state.inputType === "number" &&
            eventName == 'flxNumber_of_Other_Financial_Dependents__c'  && breakCondition === false) {
            let getUserInputValue = this.state.input[this.props.field_api_name].split(",").join("");

            breakCondition = this.handlRequiredFieldForNumberOfDependents(src, errorValue, breakCondition);

            if (src.flxType__c ==="Specify Length") {
              if (getUserInputValue < src.flxMin_Value__c || getUserInputValue > src.flxMax_Value__c) {
                errorValue[this.props.field_api_name] = src.flxError_Message__c;
                stage[this.props.field_api_name] = src.flxError_Message__c;
                stage["stage"]= this.props.stage;
                this.props.appvalidations(stage);
                this.setState({
                  errors: errorValue
                });
                breakCondition = true;
              }
            }else if(src.flxType__c ==="Specify Range"){
              if (getUserInputValue < src.flxMin_Value__c || getUserInputValue > src.flxMax_Value__c) {
                errorValue[this.props.field_api_name] = src.flxError_Message__c;
                this.setState({
                  errors: errorValue
                });
                stage[this.props.field_api_name] = src.flxError_Message__c;
                stage["stage"]= this.props.stage;
                this.props.appvalidations(stage);
                breakCondition = true;
              }
            }
          }
      })
    }
  }





 

// removeFromHistory() {

//   if (window.$('#forgotModal').hasClass('show') === false) {
//       window.$('#forgotModal').modal('hide');
//       window.$('.modal-backdrop').removeClass('show');
//       window.$('body').removeClass('modal-open');
//       window.$('.modal-backdrop').remove();
//   }
// }
 

  assignErrorMessage(errorVal, source, stages) {

    errorVal[this.props.field_api_name] = source.flxError_Message__c;
    stages[this.props.field_api_name] = source.flxError_Message__c;
    stages["stage"] = this.props.stage;
    this.props.appvalidations(stages);
    this.setState({
      errors: errorVal
    });
  }

  handleChange(event) {

    let name = event.target.name;
    let value = event.target.value.trim();
    // analyticsTypeEvent(name)
   this.setState({ showValidation: 'yes' });
    let Currentdatas = document.getElementById(this.props.field_api_name).value;
    let caret = event.target.selectionStart;
    const allDetailDatas = Object.assign({}, ...this.props.saveApplicationData);

    let currecyCurrentLength = '';

    // To test the handle change functionality
    currecyCurrentLength = this.CheckHandleChangeFunctionality(Currentdatas,name,value,allDetailDatas,currecyCurrentLength)

    const { Loan_Term_Requested__c } = allDetailDatas;

    if (event.target.name === 'genesis__Loan_Amount__c' && Loan_Term_Requested__c && Loan_Term_Requested__c !== '') {
      let stages = {};
      let stagesValues={}
        let loanTermStagesValues={}
         loanTermStagesValues['Loan_Term_Requested__c'] = '';
         stages['Loan_Term_Requested__c'] = 'This is Required';
         stagesValues['Loan_Term_Requested__c'] = false;
        stages['stage'] = this.props.stage;
        this.props.saveApplicationDatas(loanTermStagesValues);
        this.props.appvalidations(stages);
        this.props.secondSectionValidationUpdate(stagesValues);
    }

    // To validate the loan amount
    value = this.loanAmountValidation(event,value);

    console.log('loanAmountValidation_value',value);
    if (this.props.field_api_name === 'flxPersonMobile__c' && value.length === 12) {
        event.preventDefault();
        return false;
    }
    if (this.props.field_api_name === 'Postal_Code__c' || this.props.field_api_name === 'PreviousPostal_Code__c') {
      if (value.length >= 9) {
        event.preventDefault();
        return false;
      }
    }


    this.setState({ showValidation: 'yes' });
    let input = this.state.input;
    input[event.target.name] = value;
    if (event.target.name !== 'flxPersonConfirmEmail__c') {
      let inputValues = {}
      inputValues[event.target.name] = value;
      this.props.saveApplicationDatas(inputValues)
    }
    this.setState({
      input
    });

    let updatedCurrentLength='';
    updatedCurrentLength = value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",").length;
    if (value.toString().indexOf(".") >= 0) {
      let decimal_pos = value.toString().indexOf(".");
      let left_side = value.substring(0, decimal_pos);
      let right_side = value.substring(decimal_pos);
      let replaceLeftSide=left_side.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      right_side = right_side.substring(0, 3);
      let concatValue=replaceLeftSide+right_side;
      updatedCurrentLength=concatValue.length;
    }

    if (this.props.field_type === 'Currency' && caret != 0) {
        caret = updatedCurrentLength - currecyCurrentLength + caret;
      
      console.log(caret)
    }


    if (event.target.name === 'genesis__Loan_Amount__c') {
      this.props.getAmountRequested(value)
    }

    this.handlingValidations();
  }

  updateCuruncyamount(value) {
    if (value.toString().indexOf(".") >= 0) {
      let decimal_pos = value.toString().indexOf(".");
      let left_side = value.substring(0, decimal_pos);
      let right_side = value.substring(decimal_pos);
      if(left_side){
      left_side = left_side.replace(/\D/g, "");
      let replaceLeftSide = left_side.split("").reverse().join("").match(/.{1,3}/g).join(",").split("").reverse().join("");
      return replaceLeftSide + right_side;
      }
    }
  }

  CheckHandleChangeFunctionality = (Currentdatas,name,value,allDetailDatas,currecyCurrentLength) => {
    
    if (this.props.field_type === 'Currency') {
      currecyCurrentLength = Currentdatas.length;
    }

    this.checkQuestionFieldValidation(name,value);
   
    const { field_api_name } = this.props;
    const { Current_Residential_Status__c } = allDetailDatas;

    if (field_api_name === 'Fractional_Residential_Expense__c' &&
        (Current_Residential_Status__c === 'Homeowner - mortgaged' || Current_Residential_Status__c === 'Tenant/Boarding')) {
          if(value.charAt(0)==='0'){
            let stagesValues={}
            stagesValues['Fractional_Residential_Expense__c'] = 'Amount cannot be £0 if your Residential status is Homeowner - mortgaged or Tenant/Boarding';
            stagesValues['stage'] = this.props.stage;
            let errors = this.state.errors;
            errors['Fractional_Residential_Expense__c'] = 'Amount cannot be £0 if your Residential status is Homeowner - mortgaged or Tenant/Boarding';
            this.props.appvalidations(stagesValues);
            this.setState({
              errors
            });
            return false;
          }
    }

    setTimeout(() => {
      if (name === 'flxNumber_of_Other_Financial_Dependents__c') {
        this.handleChangeForNumberOfDependents(name);
      }
    }, 200);
    return currecyCurrentLength;
  }

  // To validate the loan amount
  loanAmountValidation = (event,value)=>{
    if (this.props.field_type === 'Currency' && value.length !== 0) {
        if (event.target.name === 'genesis__Loan_Amount__c') {
          value = this.handleCheckLoanAmountValidation(value);
        }else{
          value = value.replace(/\D/g, "").split(',').join('');
        }
    }
    return value;
  }

  handleCheckLoanAmountValidation = (value)=> {
    if(value.toString().indexOf(".") >= 0){
      let decimal_position = value.toString().indexOf(".");
      let leftSide = value.substring(0, decimal_position);
      let rightSide = value.substring(decimal_position);
      let replaceLeftSide=leftSide.replace(/\D/g, "").split(',').join('');
      rightSide = rightSide.substring(0, 3);
      if(rightSide.length===2){
        rightSide=rightSide+'0';
      }

    if(rightSide.length===1){
        rightSide='.00';
    }
    value=replaceLeftSide+rightSide;
    }else{
      value = value.replace(/\D/g, "").split(',').join('')+'.00';
    }
    return value;
  }

  //To check application question field validation
  checkQuestionFieldValidation = (name,value) => {

    if ((this.props.field_api_name === 'genesis__bank_account_number__c' || this.props.field_api_name === 'flxNumber_of_Other_Financial_Dependents__c') && value && isNaN(value)) {
      return false;
    }

    setTimeout(() => {
      if (name === 'genesis__Loan_Amount__c' || name === 'Total_Monthly_Income__c') {
        this.handleChangeOfLoanAmount(name);
      }

    }, 200);

    setTimeout(() => {
      switch (name) {
        case 'Last_Name':
          this.handleChangeForLastNameForSectionUpdate();
          break;
        case 'First_Name':
          this.handleChangeForFirstNameForSectionUpdate();
          break;
        case 'flxNumber_of_Other_Financial_Dependents__c':
          this.handleChangeForNumberOfDependentsForSectionUpdate(name);
          break;
        case 'occupation':
          this.handleChangeForOccupationForSectionUpdate(name);
          break;
        default:
          // Handle other cases if needed
      }
    }, 800);
  }

  setPopupView = (src) => {
    if(this.props.field_api_name=='Fractional_Residential_Expense__c' || 
      this.props.field_api_name== 'Fractional_Living_Expense__c' || 
      this.props.field_api_name === 'monthly_expense_financial_commitments' || 
      this.props.field_api_name === 'genesis__Loan_Amount__c' || 
      this.props.field_api_name === 'Total_Monthly_Expenses__c'){
        this.setState(
          {showInformation: src.flxError_Message__c
        })
      }
      else if (this.props.field_api_name == 'Total_Monthly_Income__c' && src.flxMin_Value__c == process.env.REACT_APP_MINIMUM_MONTHLY_INCOME){
        this.setState(
          {
            showInformation:src.flxError_Message__c.replaceAll("\n","<br>"),
            maxValue: src.flxMax_Value__c
          })
      }
  }

  setMaximumThresholdLimitForLoanAmount = () => {
    const cookie = new Cookies();

    const fieldName = 'genesis__Loan_Amount__c'
    if(cookie.get('loanAmount') !== '' && cookie.get('loanAmount') !== undefined){
      if(this.props.field_api_name === fieldName){
        if (this.props.field_api_validations !== '') {
          this.props.field_api_validations.map((src) => {
            if (src.flxType__c === 'Maximum Range') {
              localStorage.setItem('maxLoanAmountRange', src.flxMax_Value__c)
              localStorage.setItem('minLoanAmountRange', src.flxMin_Value__c)
            }
          })
        }
      }
    }
  }

  setMaximumThresholdLimitForQuoteLoanAmount = () => {
    const fieldName = 'genesis__Loan_Amount__c'
    if(localStorage.getItem('quote-loan-amount')){
      if(this.props.field_api_name === fieldName){
        if (this.props.field_api_validations !== '') {
          this.props.field_api_validations.map((src) => {
            if (src.flxType__c === 'Maximum Range') {
              localStorage.setItem('maxLoanAmountRange', src.flxMax_Value__c)
              localStorage.setItem('minLoanAmountRange', src.flxMin_Value__c)
            }
          })
        }
      }
    }
  }
// Validation of required fields
  handleRequiredFieldValidation = (src,breakCondition,errorValues,stages) => {
    if (src.flxType__c === 'Required Field' && breakCondition === false) {
      if (this.state.input[this.props.field_api_name] === '') {
        errorValues[this.props.field_api_name] = 'This is Required';
        stages[this.props.field_api_name] = 'This is Required';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        this.setState({
          errors: errorValues
        });

        breakCondition = true;

      }
    }
    if (src.flxType__c === 'Do not allow negative value') {
      if (this.state.value < 0 && breakCondition === false) {
        errorValues[this.props.field_api_name] = 'Please Enter positive Number';
        stages[this.props.field_api_name] = 'Please Enter positive Number';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        this.setState({
          errors: errorValues
        });

        breakCondition = true;
      }
    }
    return breakCondition;
  }

  
  //To validate expenses check
  handleExpensesvalidation = (breakCondition,errorValues,stages,src) => {
    if (this.props.field_api_name === 'Total_Monthly_Expenses__c'  && this.state.input[this.props.field_api_name]<=0 && breakCondition === false) {
      errorValues[this.props.field_api_name] = 'Must be more than £0';
      stages[this.props.field_api_name] = 'Must be more than £0';
      stages['stage'] = this.props.stage;
      this.props.appvalidations(stages);
      this.setState({
        errors: errorValues
      });
      breakCondition = true;
    }

    if (this.props.field_api_name === 'Fractional_Living_Expense__c' && this.state.input[this.props.field_api_name]<=0 && breakCondition === false) {
          errorValues[this.props.field_api_name] = 'Must be more than £0';
          stages[this.props.field_api_name] = 'Must be more than £0';
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
          this.setState({
            errors: errorValues
          });
          breakCondition = true;
    }

    if (src.flxType__c == "Popup View") { 
      this.setPopupView(src);
    }
    return breakCondition;
  }

  validateEmail = (email) => {
    const allowedSpecialCharecters = "[!#$%&'*+/=?^._`{|}~-]";
    const notUsedConsecutiveSpecialChars = "[!#$%&'*+/=?^`{|}~]";
    const consecutiveSpecialCharsRegex =
      `(?!.*${allowedSpecialCharecters}${notUsedConsecutiveSpecialChars})` +
      `(?!.*${notUsedConsecutiveSpecialChars}${allowedSpecialCharecters})` +
      `(?!.*[.]{2})`;
    const localPartRegex = "[a-zA-Z0-9](?:[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*[a-zA-Z0-9-_])?";
    const domainPartRegex = "@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}$";
    const emailRegex = new RegExp("^" + consecutiveSpecialCharsRegex + localPartRegex + domainPartRegex + "$");
    return emailRegex.test(email);
  };

  // Validation of email props
  handleEmailpropsValidation = (src,breakCondition,errorValues,stages,stageValues) => {
    if (this.state.inputType === 'email') {
      this.props.email('');
      this.props.confirmemails('');
      if (this.state.input[this.props.field_api_name].length < src.flxMin_Value__c || this.state.input[this.props.field_api_name].length > src.flxMax_Value__c && breakCondition === false) {

        errorValues[this.props.field_api_name] = src.flxError_Message__c;
        stages[this.props.field_api_name] = src.flxError_Message__c;
        stages['stage'] = this.props.stage;
        stageValues['Email_Address'] = false
        this.props.appvalidations(stages);
        this.setState({
          errors: errorValues
        });
        breakCondition = true;
      }
      if (
        /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@[a-z0-9-]+(\.[a-z]{2,})+$/i.test(
          this.state.input[this.props.field_api_name]
        ) && this.validateEmail(this.state.input[this.props.field_api_name])) {

        errorValues[this.props.field_api_name] = '';
        stages[this.props.field_api_name] = '';
        stages['stage'] = this.props.stage;
        stageValues['Email_Address'] = true
        this.props.appvalidations(stages);
        this.setState({
          errors: errorValues
        });
      } else {

        errorValues[this.props.field_api_name] = 'Please enter a valid email';
        stages[this.props.field_api_name] = 'Please enter a valid email';
        stages['stage'] = this.props.stage;
        stageValues['Email_Address'] = false
        this.props.appvalidations(stages);
        this.setState({
          errors: errorValues
        });
        breakCondition = true;
      }
    }
    return breakCondition;
  }

  handleMobilePropsValidation = (src,errorValues,stages,breakCondition) => {
    if (this.props.field_api_name === 'flxpersonmobile__c') {
      this.props.getPhoneNumber('');
      if (this.state.value.length < src.flxMin_Value__c || this.state.value.length > src.flxMax_Value__c) {

        errorValues[this.props.field_api_name] = src.flxError_Message__c;
        stages[this.props.field_api_name] = src.flxError_Message__c;
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        this.setState({
          errors: errorValues
        });
        breakCondition = true;
      }
      let mystring = this.state.value.substring(0, 2);
      if (mystring !== '07' && breakCondition === false) {

        errorValues[this.props.field_api_name] = 'This is not a valid UK Phone Number';
        stages[this.props.field_api_name] = 'This is not a valid UK Phone Number';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        this.setState({
          errors: errorValues
        });
        breakCondition = true;
      }
    } else {
      let userInputValue = this.state.input[this.props.field_api_name].split(",").join('');
      breakCondition = this.handleMobileLengthandRange(src,userInputValue,errorValues,stages,breakCondition);
    }

    return breakCondition;
  }

  handleMobileLengthandRange = (src,userInputValue,errorValues,stages,breakCondition) => {
    if (src.flxType__c === "Specify Length") {
      if (userInputValue.length < src.flxMin_Value__c || userInputValue.length > src.flxMax_Value__c) {

        errorValues[this.props.field_api_name] = src.flxError_Message__c;
        stages[this.props.field_api_name] = src.flxError_Message__c;
        stages["stage"] = this.props.stage;
        this.props.appvalidations(stages);
        this.setState({
          errors: errorValues
        });
        breakCondition = true;
      }
    }
    if (src.flxType__c === 'Specify Range') {
      if (userInputValue < src.flxMin_Value__c || userInputValue > src.flxMax_Value__c) {
        errorValues[this.props.field_api_name] = src.flxError_Message__c;
        stages[this.props.field_api_name] = src.flxError_Message__c;
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        this.setState({
          errors: errorValues
        });
        breakCondition = true;
      }
    }
    if (this.state.input[this.props.field_api_name] === 'sortcode_bankaccount') {
      if (userInputValue.length >= 8) {

        errorValues[this.props.field_api_name] = '';
        stages[this.props.field_api_name] = '';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        this.setState({
          errors: errorValues
        });
        breakCondition = true;
      }
    }
    return breakCondition;
  }

  handleTextandCurrencyFieldValidation = (breakCondition,src,errorValues,stages) => {
    if (this.state.inputType === 'text' && breakCondition === false && this.props.field_type !== 'Currency') {
      // if(this.state.value.length<=src.flxMin_Value__c || this.state.value.length>=src.flxMax_Value__c){

      if (this.state.input[this.props.field_api_name].length < src.flxMin_Value__c) {

        errorValues[this.props.field_api_name] = 'This is Required';
        stages[this.props.field_api_name] = 'This is Required';
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        this.setState({
          errors: errorValues
        });
        breakCondition = true;
      } else if (this.state.input[this.props.field_api_name].length > src.flxMax_Value__c) {
        errorValues[this.props.field_api_name] = src.flxError_Message__c;
        stages[this.props.field_api_name] = src.flxError_Message__c;
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        this.setState({
          errors: errorValues
        });
        breakCondition = true;
      }
      this.handleFirstNameValidation(breakCondition);
      if (this.props.field_api_name === 'flxFirst_Name__c' || this.props.field_api_name === 'Middle_Name' 
      || this.props.field_api_name === 'flxLast_Name__c' || this.props.field_api_name === 'First_Name' || this.props.field_api_name === 'Last_Name') {
        let inputVal = this.state.input[this.props.field_api_name];

        if (src.flxType__c === 'Specify Length') {
          breakCondition = this.handleNameSpecificLength(inputVal,src,errorValues,stages,breakCondition);
        } else {
          errorValues[this.props.field_api_name] = src.flxError_Message__c;
          stages[this.props.field_api_name] = src.flxError_Message__c;
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
          this.setState({
            errors: errorValues
          });
          breakCondition = true;
        }
      }
    }
    return breakCondition;
  }

  handleFirstNameValidation = (breakCondition) => {
    if (this.props.field_api_name === 'First_Name') {
      let inputVal = this.state.input[this.props.field_api_name];
         const basicTitleRegex = /^(mr|ms|mrs|miss|dr|prof|rev|hon|sir|dame|lord|lady|capt|major|col|gen|lt|sgt|fr|rabbi|imam)\b/i;
         const newTitleRegex = /^(bishop|cardinal|baron|baroness|viscount|viscountess|marquess|marchioness|earl|countess|duchess|prince|king)\b/i;
         const titleRegex = new RegExp(`${basicTitleRegex.source}|${newTitleRegex.source}`, 'i');
       
         if (titleRegex.test(inputVal)) {
           let errorValues = { ...this.state.errors };
           errorValues[this.props.field_api_name] = 'Titles (e.g., Mr, Ms, Dr, etc) are not allowed in the first name.';
          
           let stages = { ...this.state.stages };
           stages[this.props.field_api_name] = 'Titles (e.g., Mr, Ms, Dr, etc) are not allowed in the first name.';
           stages['stage'] = this.props.stage;

           this.props.appvalidations(stages);
           this.setState({
             errors: errorValues
           });
         }
        breakCondition = true;
    }
    return breakCondition
  }

  handleNameSpecificLength = (inputVal,src,errorValues,stages,breakCondition) => {
    if (inputVal.length < src.flxMin_Value__c || inputVal.length > src.flxMax_Value__c) {
      errorValues[this.props.field_api_name] = src.flxError_Message__c;
      stages[this.props.field_api_name] = src.flxError_Message__c;
      stages['stage'] = this.props.stage;
      this.props.appvalidations(stages);
      this.setState({
        errors: errorValues
      });
      breakCondition = true;
    } else if (this.state.input[this.props.field_api_name].length < src.flxMin_Value__c) {
      errorValues[this.props.field_api_name] = 'This is Required';
      stages[this.props.field_api_name] = 'This is Required';
      stages['stage'] = this.props.stage;
      this.props.appvalidations(stages);
      this.setState({
        errors: errorValues
      });
      breakCondition = true;
    }
    return breakCondition;
  }

  checkAndAssignErrorMessage(errorMessage, invalidValues, inputValue, errorValues, src, stages, breakCondition) {
    if (src.flxError_Message__c === errorMessage && invalidValues.includes(inputValue)) {
      this.assignErrorMessage(errorValues, src, stages);
      breakCondition = true;
    }
    return breakCondition;
  }

  checkAddressFieldValidation(src,errorValues,stages,breakCondition){
    if(this.props.field_api_name === 'Address_Line1__c' || this.props.field_api_name === 'Town__c'
          || this.props.field_api_name === 'Previous_Address_Line1__c' || this.props.field_api_name === 'PreviousTown__c'){
            let getInputValue = this.state.input[this.props.field_api_name].split(',').join('');
              if (src.flxType__c ==='Specify Length') {
                if (getInputValue.length < src.flxMin_Value__c || getInputValue.length > src.flxMax_Value__c) {

                  errorValues[this.props.field_api_name] = src.flxError_Message__c;
                  stages[this.props.field_api_name] = src.flxError_Message__c;
                  stages['stage'] = this.props.stage;
                  this.props.appvalidations(stages);
                  this.setState({
                    errors: errorValues
                  });
                  breakCondition = true;
                }
              }
          }
          return breakCondition;
  }
  
  handleNumberFieldValidation = (src,getUserInputValue,errorValues,stages,breakCondition) => {
    if (src.flxType__c ==="Specify Length") {
      if (getUserInputValue.length < src.flxMin_Value__c || getUserInputValue.length > src.flxMax_Value__c) {

        errorValues[this.props.field_api_name] = src.flxError_Message__c;
        stages[this.props.field_api_name] = src.flxError_Message__c;
        stages["stage"]= this.props.stage;
        this.props.appvalidations(stages);
        this.setState({
          errors: errorValues
        });
        breakCondition = true;
      }
    }

    if (this.props.field_api_name === 'genesis__bank_account_number__c') {
      if (getUserInputValue.length >= 9) {
        if (this.state.errors['genesis__bank_account_number__c'] == 'Must be 8 digit numeric value') {
          errorValues[this.props.field_api_name] = src.flxError_Message__c;
          stages[this.props.field_api_name] = src.flxError_Message__c;
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
          this.setState({
            errors: errorValues
          });
          breakCondition = true;
        }
      }
    }
    return breakCondition;
  }

  handleTextFieldValidation = (src,inputValue,errorValues,stages,breakCondition) => {
    const { flxType__c, flxMin_Value__c, flxMax_Value__c } = src;
    if (flxType__c === 'Specify Length' && (inputValue.length < flxMin_Value__c || inputValue.length > flxMax_Value__c)) {
        errorValues[this.props.field_api_name] = src.flxError_Message__c;
        stages[this.props.field_api_name] = src.flxError_Message__c;
        stages['stage'] = this.props.stage;
        this.props.appvalidations(stages);
        this.setState({
          errors: errorValues
        });
        breakCondition = true;
    }
    if (this.props.field_api_name === "sortcode_bankaccount") {
      if (inputValue.length >= 8) {
        if (this.state.errors['sortcode_bankaccount'] == 'Must be 6 digit numeric value') {
          errorValues[this.props.field_api_name] = src.flxError_Message__c;
          stages[this.props.field_api_name] = src.flxError_Message__c;
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
          this.setState({
            errors: errorValues
          });
          breakCondition = true;
        }
      }
      if (src.flxType__c === 'Specify Length') {
        if (inputValue.length < src.flxMin_Value__c || inputValue.length > src.flxMax_Value__c) {

          errorValues[this.props.field_api_name] = src.flxError_Message__c;
          stages[this.props.field_api_name] = src.flxError_Message__c;
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
          this.setState({
            errors: errorValues
          });
          breakCondition = true;
        }
      }
    }
    return breakCondition;
  }

  handlingValidations = () => {
    let breakCondition = false;
    let errorValues = this.state.errors;
    errorValues[this.props.field_api_name] = '';
    this.setState({
      errors: errorValues
    });
    let stages = {};
    let stageValues = {}
    stages[this.props.field_api_name] = '';
    stages['stage'] = this.props.stage;
    this.props.appvalidations(stages);
    if (this.props.field_api_validations !== '') {
      let checkContainsValidation = this.props.field_api_validations.filter(src => src.flxType__c === 'Contains');
      if (checkContainsValidation.length > 0) {
        breakCondition = this.checkHandleContainsValidation(breakCondition, errorValues, stages);
      } else {
      this.props.field_api_validations.map((src) => {

        // Validation of required fields
        breakCondition = this.handleRequiredFieldValidation(src,breakCondition,errorValues,stages);
        breakCondition = this.handleChangeForMobileNumber(breakCondition, errorValues);

        //To validate expenses check
        breakCondition = this.handleExpensesvalidation(breakCondition,errorValues,stages,src);
        if(this.state.inputType === 'email' && this.state.input[this.props.field_api_name] === ''){
          breakCondition = false;
          }
        if (src.minmaxrequired === true && breakCondition === false) {

          // Validation of email props
          breakCondition = this.handleEmailpropsValidation(src,breakCondition,errorValues,stages,stageValues);

          let inputValue = this.state.input[this.props.field_api_name];
          breakCondition = this.handleChangeForLoanAmountAndIncome(src, breakCondition, errorValues, inputValue)

          if ((this.state.inputType === 'number' || this.props.field_type === 'Currency' || this.props.field_api_name === 'sortcode_bankaccount') && breakCondition === false) {
            breakCondition = this.handleMobilePropsValidation(src,errorValues,stages,breakCondition);
          }

          breakCondition = this.checkAndAssignErrorMessage("Must be numeric value and six zeros or six nines not allowed", ["000000", "999999"], inputValue, errorValues, src, stages,breakCondition);
          breakCondition = this.checkAndAssignErrorMessage("Must be numeric value and eight zeros or eight nines not allowed", ["00000000", "99999999"], inputValue, errorValues, src, stages,breakCondition);
          breakCondition = this.checkAddressFieldValidation(src,errorValues,stages,breakCondition);
          breakCondition = this.handleTextandCurrencyFieldValidation(breakCondition,src,errorValues,stages);
        } else if (!src.minmaxrequired && !breakCondition) {
          breakCondition = this.checkNumberandTextMinimumandmaximumValidation(breakCondition,src,errorValues,stages);
        }
      })
    }
    }

      this.props.firstSectionValidationUpdate(stageValues);
   
  }

  checkHandleContainsValidation = (breakCondition, errorValues, stages) => {
    try {
      this.props.field_api_validations.map((src) => {
        breakCondition = this.handleRequiredFieldValidation(src, breakCondition, errorValues, stages);
        if (src.minmaxrequired === true && breakCondition === false) {
          if (this.props.field_api_name === 'genesis__Loan_Amount__c') {
            let inputValue = this.state.input[this.props.field_api_name];
            let updatedErrorMessage = this.handleRetailerErrorMessage(src,inputValue,errorValues,stages);
            if(updatedErrorMessage === false){
              return updatedErrorMessage;
            }
          }
        } else if (!src.minmaxrequired && !breakCondition) {
          breakCondition = this.checkNumberandTextMinimumandmaximumValidation(breakCondition, src, errorValues, stages);
        }
      });
      return breakCondition;
    } catch (error) {
      frontEndLogs('Catch Error', true, 400, 'checkHandleContainsValidation', error.message);
    }
  }

  handleRetailerErrorMessage = (src,inputValue,errorValues,stages)=>{
    if (src.flxMin_Value__c > inputValue || src.flxMax_Value__c < inputValue) {
      let errorMessage = src.flxError_Message__c;
      if (errorMessage.includes('LOAN_AMOUNT')) {
        let loanAmount = this.updateCuruncyamount(inputValue)
        if (loanAmount) {
          errorMessage = errorMessage.replace('LOAN_AMOUNT', loanAmount)
        } else {
          return false;
        }
      }
      errorValues['genesis__Loan_Amount__c'] = errorMessage;
      stages['genesis__Loan_Amount__c'] = errorMessage;
      stages['stage'] = this.props.stage;
      this.props.appvalidations(stages);
      this.setState({
        errors: errorValues
      });
    }
  }

  checkNumberandTextMinimumandmaximumValidation = (breakCondition,src,errorValues,stages) => {
    let inputValue;
    if (breakCondition === false) {
      if (this.state.inputType === 'number' || this.state.inputType === 'text') {
        inputValue = this.state.input[this.props.field_api_name].split(',').join('');
        breakCondition = this.state.inputType === 'number'
          ? this.handleNumberFieldValidation(src, inputValue, errorValues, stages, breakCondition)
          : this.handleTextFieldValidation(src, inputValue, errorValues, stages, breakCondition);
      }
    }
    return breakCondition;
  }

  onKeyPressDataForMobileNumber = (event,invalidCharacters) => {

    if (this.props.field_api_name === 'Mobile_Number' ||this.props.field_api_name === 'flxpersonmobile__c') {
      if (!/\d/.test(event.key)) {
        event.preventDefault();
        return false;
      }
      if (invalidCharacters.includes(event.key)) {
          event.preventDefault();
      }
      if(event.target.value.length >= 11){
        event.preventDefault();
      }

      if (/[a-zA-Z]/.test(event.key)) {
        event.preventDefault();
      }
    }

    if (this.props.field_api_name === 'flxpersonmobile__c') {
      if (!/\d/.test(event.key)) {
        event.preventDefault();
      }
    }

  }

  onKeyPressDataForFirstNameAndLastName = (event) => {

    if (this.props.field_api_name === 'First_Name') {
      if (!/^(?!.*[0-9!@#$%^&*()_+{}[\]:;<>,.?\\|])(([a-zA-Z ]+( [a-zA-Z]+)?)|([a-zA-Z]+))$/.test(event.key)) {
        event.preventDefault();
        return false;
      }

    }

    if (this.props.field_api_name === 'flxLast_Name__c' || this.props.field_api_name === 'Last_Name') {
      if (!/[a-z ]/i.test(event.key) || /\d/.test(event.key)  || /[-'~`!@#$£%^&*()_+=[\]{};:"\\|,.<>/?]+/.test(event.key) || /"+/.test(event.key)) {
        event.preventDefault();
        return false;
      }
    }
  }

  handleChangeForMobileNumber = (breakCondition, errorValues) => {
    let stageValues= {}, stages = {};
    if(this.props.field_api_name === 'Mobile_Number' || this.props.field_api_name === 'flxpersonmobile__c') {
      let getInputValue = this.state.input[this.props.field_api_name];
      let ukPhoneNumberPrefix = getInputValue.substring(0, 2);
      if(getInputValue.length === 11){
        errorValues[this.props.field_api_name] = '';
        stages[this.props.field_api_name] = '';
        stages['stage'] = this.props.stage;
        stageValues['Mobile_Number'] = true
        this.props.appvalidations(stages);
        this.setState({
          errors: errorValues
        });
        breakCondition = true;

        if(ukPhoneNumberPrefix !== '07'){
          errorValues[this.props.field_api_name] = 'Enter a valid mobile number';
          stages[this.props.field_api_name] = 'Enter a valid mobile number';
          stages['stage'] = this.props.stage;
          stageValues['Mobile_Number'] = false
          this.props.appvalidations(stages);
          this.setState({
            errors: errorValues
          });
          breakCondition = true;
        }
      }
      else if(getInputValue.length == 0){
        errorValues[this.props.field_api_name] = 'This is Required';
        stages[this.props.field_api_name] = 'This is Required';
        stages['stage'] = this.props.stage;
        stageValues['Mobile_Number'] = false
        this.props.appvalidations(stages);
        this.setState({
          errors: errorValues
        });
        breakCondition = true;
      }
      else if(getInputValue.length >=1 ||  getInputValue.length <11){
        errorValues[this.props.field_api_name] = 'Invalid UK mobile number format';
        stages[this.props.field_api_name] = 'Invalid UK mobile number format';
        stages['stage'] = this.props.stage;
        stageValues['Mobile_Number'] = false
        this.props.appvalidations(stages);
        this.setState({
          errors: errorValues
        });
        breakCondition = true;
      }
        
    }

    this.props.firstSectionValidationUpdate(stageValues);

    return breakCondition;
  }

  handleChangeForLoanAmountAndIncome = (src, breakCondition, errorValues, inputValue) => {
    let stages = {};

    if (this.props.field_api_name === 'genesis__Loan_Amount__c' || this.props.field_api_name === 'Total_Monthly_Income__c') {
      if (src.flxType__c === 'Minimum Range') {
        if (src.flxMin_Value__c > inputValue) {
          errorValues[this.props.field_api_name] = src.flxError_Message__c;
          stages[this.props.field_api_name] = src.flxError_Message__c;
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
          this.setState({
            errors: errorValues
          });
          breakCondition = true;
        }
      }
      if (src.flxType__c === 'Maximum Range') {
        if (src.flxMax_Value__c < inputValue) {
          errorValues[this.props.field_api_name] = src.flxError_Message__c;
          stages[this.props.field_api_name] = src.flxError_Message__c;
          stages['stage'] = this.props.stage;
          this.props.appvalidations(stages);
          this.setState({
            errors: errorValues
          });
          breakCondition = true;
        }
      }

    }
    
    return breakCondition;
  }

  handleChangeOfLoanAmount = (name) => {
    let stageValues ={}
    if(name === 'genesis__Loan_Amount__c') {
      const allDetails = Object.assign({}, ...this.props.applicationValidation);  

      if(allDetails['genesis__Loan_Amount__c'] == '') {
        stageValues['genesis__Loan_Amount__c'] = true
        this.props.secondSectionValidationUpdate(stageValues);
      }
      else {
        stageValues['genesis__Loan_Amount__c'] = false
        this.props.secondSectionValidationUpdate(stageValues);
      }

    }

    if(name === 'Total_Monthly_Income__c') {
      const allDetails = Object.assign({}, ...this.props.applicationValidation);  

      if(allDetails['Total_Monthly_Income__c'] == '') {
        stageValues[name] = true
        this.props.fourSectionValidationUpdate(stageValues);
      }
      else {
        stageValues[name] = false
        this.props.fourSectionValidationUpdate(stageValues);
      }

    }
  }

  handleChangeForNumberOfDependentsForSectionUpdate = (name) => {
    const allUserDetails = Object.assign({}, ...this.props.applicationValidation);  
    let stageValues = {}

    if(name === 'flxNumber_of_Other_Financial_Dependents__c') {
      if(allUserDetails['flxNumber_of_Other_Financial_Dependents__c'] == '') {
        stageValues['flxNumber_of_Other_Financial_Dependents__c'] = true
      }
      else {
        stageValues['flxNumber_of_Other_Financial_Dependents__c'] = false
      }
  
      this.props.fourSectionValidationUpdate(stageValues);
    }
    
  }

  handleChangeForOccupationForSectionUpdate = (name) => {
    const allUserDetails = Object.assign({}, ...this.props.applicationValidation);  
    let stageValues = {}

    if(name === 'occupation') {
      if(allUserDetails['occupation'] == '') {
        stageValues['occupation'] = true
      }
      else {
        stageValues['occupation'] = false
      }
  
      this.props.fourSectionValidationUpdate(stageValues);
    }
    
  }

  handleChangeForLastNameForSectionUpdate = () => {
    const allUserDetails = Object.assign({}, ...this.props.applicationValidation);  
    let stageValues = {}

    if(allUserDetails['Last_Name'] == '') {
      stageValues['Last_Name'] = true
    }
    else {
      stageValues['Last_Name'] = false
    }

    this.props.firstSectionValidationUpdate(stageValues);
  }

  handleChangeForFirstNameForSectionUpdate = () => {
    const allUserDetails = Object.assign({}, ...this.props.applicationValidation);  
    let stageValues = {}

    if(allUserDetails['First_Name'] == '') {
      stageValues['First_Name'] = true
    }
    else {
      stageValues['First_Name'] = false
    }

    this.props.firstSectionValidationUpdate(stageValues);
  }


  onKeyPressDataForAccountNumber = (event, invalidCharacters) => {
    if (this.props.field_api_name === 'genesis__bank_account_number__c') {
      if (!/\d/.test(event.key)) {
        event.preventDefault();
        return false;
      }

      if (event.target.value.length > 7) {
        event.preventDefault();
        return false;
      }
      if (invalidCharacters.includes(event.key)) {
        event.preventDefault();
        return false;
      }
    }
  }

  onKeyPressDataForFinanicalDependents = (event, invalidCharacters) => {
    if (this.props.field_api_name === 'flxNumber_of_Other_Financial_Dependents__c') {
      if (!/\d/.test(event.key)) {
        event.preventDefault();
        return false;
      }
      if (event.target.value.length+1 > 1) {
        event.preventDefault();
        return false;
      }

      if (invalidCharacters.includes(event.key)) {
        event.preventDefault();
      }
    }
  }

  onKeyPressDataForEmailAndSortCode = (event) => {

    if (this.props.field_api_name === 'occupation') {

      if (!/[a-z ]/i.test(event.key)) {
        event.preventDefault();
        return false;
      }

    }

    if (this.props.field_api_name === 'flxPersonEmail__c' || this.props.field_api_name === 'Email_Address') {
      if (event.target.value.length > 49) {
        event.preventDefault();
      }
    }

    if (this.props.field_api_name === 'sortcode_bankaccount') {

      if (event.key === "-") {
        event.preventDefault();
        return false;
      }
    }


  }
  


  onKeyPressData = (event) => {
    event.persist();


    let invalidChars = [
      "e",
      "E",
      "+",
      ".",
      ",",
      "-"
    ];

    let validatedResponse = this.handleAddressQuestionFieldValidation(this.props.field_api_name,event.key);
    if(!validatedResponse){
      event.preventDefault();
      return false;
    }

    this.onKeyPressDataForFirstNameAndLastName(event);
    
    this.onKeyPressDataForEmailAndSortCode(event)
    
    this.onKeyPressDataForMobileNumber(event, invalidChars)

    this.onKeyPressDataForAccountNumber(event, invalidChars);
   

    this.onKeyPressDataForFinanicalDependents(event, invalidChars)

    if (this.props.field_api_name === 'sortcode_bankaccount' || this.props.field_api_name === 'genesis__bank_account_number__c' || this.props.field_api_name === 'flxNumber_of_Other_Financial_Dependents__c') {
   

      if (event.key === "-") {
        event.preventDefault();

        return false;
      }
      // if (/(([0-9]+)(\.|,)([0-9]){2})/g.test(event.key)) {
      //   event.preventDefault();
      //   return false;
      // }

      // if (invalidChars.includes(event.key)) {
      //   event.preventDefault();
      // }
    }
  


    if(this.props.field_api_name === 'genesis__Loan_Amount__c'){
      if (!/([\d.,]+)/g.test(event.key)) {
        event.preventDefault();
      }
    }

    if (this.props.field_type === 'Currency' && this.props.field_api_name != 'genesis__Loan_Amount__c') {
      if (/((\d+)([.,])(\d){2})/g.test(event.key)) {
        event.preventDefault();
      }
      if (invalidChars.includes(event.key)) {
        event.preventDefault();
      }
    }
    
  }

  onlyAcceptInputPasteCheckForMobileNumber = (event) => {
    if(this.props.field_api_name === 'flxpersonmobile__c' || this.props.field_api_name === 'Mobile_Number'){
      if(!/\d/.test(event.clipboardData.getData('Text')) || /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(event.clipboardData.getData('Text'))){
        event.preventDefault();
      }
    }
  }
  
  onlyAcceptInputsPaste = (event) => {
    const alphaRegexPattern = /[a-z ]/i
    if (this.props.field_api_name === 'flxFirst_Name__c' || this.props.field_api_name === 'occupation') {

      if (!alphaRegexPattern.test(event.clipboardData.getData('Text')) || /\d/.test(event.clipboardData.getData('Text')) || /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(event.clipboardData.getData('Text'))) {
        event.preventDefault();
      }
    }
    if (this.props.field_api_name === 'sortcode_bankaccount' || this.props.field_api_name === 'genesis__bank_account_number__c') {
      if (event.clipboardData.getData('Text').length <= 6) {
        return false
      }
      if (!/\d/.test(event.clipboardData.getData('Text')) || /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(event.clipboardData.getData('Text'))) {
        event.preventDefault();
      }
    }

    this.excludeAddressFields(event);
    this.onlyAcceptInputPasteCheckForMobileNumber(event);
  } 

  excludeAddressFields = (event)=>{
    const excludedFields = [
      'Current_Flat_No__c',
      'Current_House_Name__c',
      'HouseNumber__c',
      'Address_Line1__c',
      'Address_Line2__c',
      'Town__c'
    ];
    
    if (!excludedFields.includes(this.props.field_api_name) &&
      this.props.field_type === 'Text' &&
      !/^[\x20-\x7F]*$/.test(event.clipboardData.getData('Text'))) {
        event.preventDefault();
    }
  }

  handleAddressQuestionFieldValidation = (fieldName,value) => {
    if(fieldName === 'Current_Flat_No__c' || fieldName === 'Current_House_Name__c' || fieldName === 'HouseNumber__c' || fieldName === 'Address_Line1__c' || fieldName === 'Address_Line2__c' || fieldName === 'Town__c'){
      return handleAddressFiledValidation(fieldName,value);
    }else{
      return true;
    }
  }

  onlyAcceptInputs = (e) => {
    if (this.props.field_api_name === 'flxPersonConfirmEmail__c') {
      e.preventDefault();
    }
    let validatedResponse = this.handleAddressQuestionFieldValidation(this.props.field_api_name,e.clipboardData.getData('Text'))
    if(!validatedResponse){
      e.preventDefault();
    }

    if(this.props.field_api_name === 'Postal_Code__c' || this.props.field_api_name === 'PreviousPostal_Code__c'){
      if (!/^[a-zA-Z0-9\s]*$/.test(e.clipboardData.getData('Text'))){
        e.preventDefault();
      }
    }

    if (this.props.field_api_name === 'First_Name' || this.props.field_api_name === 'flxLast_Name__c' ||this.props.field_api_name === 'Last_Name') {
      if (!/[a-z ]/i.test(e.clipboardData.getData('Text')) || /\d/.test(e.clipboardData.getData('Text')) || /[-'!@#$£%^&*~()_+=[\]{};:"\\|,.<>/?]+/.test(e.clipboardData.getData('Text'))) {
        e.preventDefault();
      }
    }

    this.onlyAcceptInputsPaste(e);
    if(this.props.field_api_name === 'flxNumber_of_Other_Financial_Dependents__c'){
      if (!/\d/.test(e.clipboardData.getData('Text')) || /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(e.clipboardData.getData('Text')) || e.clipboardData.getData('Text') > 9) {
        e.preventDefault();
      }
    }
  }

  onKeyPressDown = (event) => {
    let validateResponse = this.handleAddressQuestionFieldValidation(event.target.name,event.key);
    if (!validateResponse) {
      return false;
    }
    this.setState({ keyData: event.key });
  }

  occupationValidation = (userAllowValue) => {
    if (this.props.employmentStatus === true && userAllowValue === true && this.props.field_api_name === 'occupation') {
      userAllowValue = true
    }
    return userAllowValue
  }

  occupationCheck = (userAllowValue) => {
    if (this.props.employmentStatus === false && this.props.field_api_name === 'occupation') {
      userAllowValue = false;
    }
    return userAllowValue
  }

  checkIfMobileVerified = (userAllDetailsData, fieldDisableValue) => {

    if(this.props.field_api_name == 'Mobile_Number' && this.props.editable === false && this.props.isMobileVerified  && userAllDetailsData[this.props.field_api_name] != '' && userAllDetailsData[this.props.field_api_name] != null && userAllDetailsData !== undefined) {
      fieldDisableValue = true
    }

    return fieldDisableValue;    
  }

  checkIfChangeEmailClicked = (userAllDetailsData, fieldDisableValue) => {
    if(this.props.field_api_name == 'First_Name' && this.props.editable === false && this.props.isChangeEmail  && userAllDetailsData[this.props.field_api_name] != '' && userAllDetailsData[this.props.field_api_name] != null && userAllDetailsData !== undefined) {
      fieldDisableValue = true
    }

    if(this.props.field_api_name == 'Last_Name' && this.props.editable === false && this.props.isChangeEmail  && userAllDetailsData[this.props.field_api_name] != '' && userAllDetailsData[this.props.field_api_name] != null && userAllDetailsData !== undefined) {
      fieldDisableValue = true
    }

    if(this.props.field_api_name == 'Mobile_Number' && this.props.editable === false && this.props.isChangeEmail  && userAllDetailsData[this.props.field_api_name] != '' && userAllDetailsData[this.props.field_api_name] != null && userAllDetailsData !== undefined) {
      fieldDisableValue = true
    }

    return fieldDisableValue;
  }

  checkIfEmailVerified = (userAllDetailsData, fieldDisableValue) => {
    if(this.props.field_api_name == 'Email_Address' && this.props.editable === false && this.props.isEmailVerified  && userAllDetailsData[this.props.field_api_name] != '' && userAllDetailsData[this.props.field_api_name] != null && userAllDetailsData !== undefined) {
      fieldDisableValue = true
    }

    return fieldDisableValue;
  }

  prepopulateCondition = (userAllDetailsData, fieldDisableValue) => {

    if(this.props.isMobileVerified) {
      fieldDisableValue = this.checkIfMobileVerified(userAllDetailsData, fieldDisableValue);
    }

    if(this.props.isEmailVerified) {
      fieldDisableValue = this.checkIfEmailVerified(userAllDetailsData, fieldDisableValue);
    }

    if(this.props.isChangeEmail) {
      fieldDisableValue = this.checkIfChangeEmailClicked(userAllDetailsData, fieldDisableValue);
    }

    if(this.props.field_api_name == 'First_Name' && this.props.editable === false 
      && !this.props.firstNameFlag && userAllDetailsData[this.props.field_api_name] != '' && 
      userAllDetailsData[this.props.field_api_name] != null) {
        fieldDisableValue = false
        return fieldDisableValue
    }

    if (this.props.editable === false && this.props.prepopulateStatus === true && 
      userAllDetailsData[this.props.field_api_name] != '' && userAllDetailsData[this.props.field_api_name] != null 
      && userAllDetailsData !== undefined && this.props.prepopulateCondition === true) {
      fieldDisableValue = true
      return fieldDisableValue
    }

    fieldDisableValue = this.userDetailsPrepopulateCondition(userAllDetailsData, fieldDisableValue);
    return fieldDisableValue
  }

  disableNameFieldOnAmending = (userAllDetailsData, fieldDisableValue) => {
    if (this.props.goBackAmendClicked) {
      if (this.props.field_api_name == 'First_Name' || this.props.field_api_name == 'Last_Name') {
        if (userAllDetailsData?.[this.props.field_api_name]) {
          fieldDisableValue = true;
        }
      }
    }
    return fieldDisableValue
  }

  userDetailsPrepopulateCondition = (userAllDetailsData, fieldDisableValue) => {
    if(this.props.field_api_name == 'First_Name' && this.props.editable === false 
       && this.props.firstNameFlag && userAllDetailsData[this.props.field_api_name] != '' && 
      userAllDetailsData[this.props.field_api_name] != null && 
      (this.props.userDetailsPopulateStatus ||this.props.prepopulateStatus)) {
      fieldDisableValue = true
    }

    if(this.props.field_api_name == 'Last_Name' && this.props.editable === false  && this.props.lastNameFlag && 
      userAllDetailsData[this.props.field_api_name] != '' && userAllDetailsData[this.props.field_api_name] != null && 
      (this.props.userDetailsPopulateStatus ||this.props.prepopulateStatus)) {
      fieldDisableValue = true
    }

    if(this.props.field_api_name == 'Email_Address' && this.props.editable === false && this.props.emailFlag && 
      userAllDetailsData[this.props.field_api_name] != '' && userAllDetailsData[this.props.field_api_name] != null 
      && (this.props.userDetailsPopulateStatus ||this.props.prepopulateStatus)) {
      fieldDisableValue = true
    }

    if(this.props.field_api_name == 'Mobile_Number' && this.props.editable === false &&  this.props.mobileNumberFlag 
      && userAllDetailsData[this.props.field_api_name] != '' && userAllDetailsData[this.props.field_api_name] != null && 
      (this.props.userDetailsPopulateStatus ||this.props.prepopulateStatus)) {
      fieldDisableValue = true
    }

    return fieldDisableValue;
  }

  residentialFieldDisableCheck = (userAllDetails, fieldDisableValue) => {
    if (this.props.field_api_name === 'Fractional_Residential_Expense__c' &&
        (userAllDetails['Current_Residential_Status__c'] === 'Homeowner - no mortgage' || 
         userAllDetails['Current_Residential_Status__c'] === 'Living with parents/family')) {
        fieldDisableValue = false;
    }
    return fieldDisableValue
  }

  classNameForInputField = (userAllDetailData, fieldDisableValue) => {

    // styling after amount is entered for currency input field
    if(this.props.field_type === 'Currency' && userAllDetailData[this.props.field_api_name] !== undefined && userAllDetailData[this.props.field_api_name] !== '') {
      return ('form-control custom-cat-displays')
    }
    // styling when the prepopulated input fields are disabled
    else if(fieldDisableValue){
      return('form-control disable-field')
    }
    // styling for input fields which are editable
    else {
      return('form-control');
    }

  }

  classNameForDivElement= (props) => {
   
      return('dynamic-input'+" "+props.field_api_name);
    
  }

  onBlurEvent = () => {
    if (this.props.onBlurInputFiled) {
      if (this.inputRef.current) {
        this.inputRef.current.blur();
      }
    }
  }

  render() {
    const allDetails = Object.assign({}, ...this.props.applicationValidation);
    const allDetailData = Object.assign({}, ...this.props.saveApplicationData);
    let allowValue = this.state.showDev[this.props.field_api_name];
    this.onBlurEvent();
    allowValue = this.occupationValidation(allowValue);

    allowValue = this.occupationCheck(allowValue)
    let disableValue = false
    disableValue = this.prepopulateCondition(allDetailData, disableValue);
    
    disableValue = this.disableNameFieldOnAmending(allDetailData,disableValue);
    if(this.props.field_api_name==='Total_Expenditure__c'){
      disableValue = true
    }
    if(this.props.field_api_name === 'Mobile_Number'){
      disableValue = this.props.disableMobileNumberField;
    }
    let minNumber = this.props.field_api_name === 'flxNumber_of_Other_Financial_Dependents__c' ? 0 : ''
    let maxNumber =this.props.field_api_name === 'flxNumber_of_Other_Financial_Dependents__c' ? 9 : ''
    disableValue = this.residentialFieldDisableCheck(allDetailData, disableValue)

    if (allowValue === true) {
      return (

        <React.Fragment>

{this.props.field_api_name == "Fractional_Residential_Expense__c" &&
                
                <span className='step-information'>
                  {this.props.field_fieldinfo}
                </span>
              
            }

        {this.props.field_question !== '' && this.props.field_api_name != "Total_Expenditure__c" &&
            <div className={this.classNameForDivElement(this.props)} >
              
              <div className="form-group">
                <div className='d-flex flex-row'>
                  <span>{this.props.field_question}
                  {(this.props.flxQuestionMoreInfo) &&
                    <a className='fieldTip'>
                      <span>
                          <img src="images/humm-infoIcon.svg" alt="humm-logo" />
                          {this.props.flxQuestionMoreInfo}
                      </span>
                      <img src="images/more-info-icon.svg" alt="info-icon" />
                    </a>
                  }
                  </span>
                </div>

                <input type={this.state.inputType} name={this.props.field_api_name} value="" readOnly={true} style={{ display: "none", opacity: 0, position: "absolute" }} autoComplete="off" />

                

                <div className={this.props.field_type === 'Currency' ? 'pound-input' : ''}>
                  {this.props.field_type === 'Currency' && allDetailData[this.props.field_api_name] !== '' && 
                  allDetailData[this.props.field_api_name] !== undefined &&
                    <span className="pound-span">£</span>
                  }
                  <input type={this.state.inputType}
                  min= {minNumber}
                  max={maxNumber}
                  ref={this.inputRef}
                  onWheel={(blurEvent) => { blurEvent.target.blur() }} lang="en" 
                  onKeyPress={(keyPressEvent) => { this.onKeyPressData(keyPressEvent) }} 
                  onKeyDown={(keyDownEvent) => { this.onKeyPressDown(keyDownEvent) }} 
                  className={this.classNameForInputField(allDetailData, disableValue)} 
                  id={this.props.field_api_name} 
                  name={this.props.field_api_name} 
                  onFocus={this.onFocus}

                    aria-describedby="email"

                    value={allDetailData[this.props.field_api_name] !== '' ? this.state.value : ''}
                    placeholder={this.props.field_placeholder}
                    onChange={(e) => { this.props.fieldChange(this.props.field_api_name, e.target.value, this.props.field_type, e, this.state.keyData); this.handleChange(e) }}
                    disabled = {disableValue}
                    onClick = {(e) => analyticsClickEvent(this.props.field_label,'')}
                    onBlur={(e) => {this.props.onBlurEvent(e,this.state.showInformation, this.state.maxValue, this.props.field_api_name,this.props.field_label)
                  }}
                    onPaste={(e) => { this.onlyAcceptInputs(e) }} autoCorrect="off" autoCapitalize="none" spellCheck="false" />

                  {(allDetails[this.props.field_api_name] == "" &&  allDetailData[this.props.field_api_name] !== '' && 
                  allDetailData[this.props.field_api_name] !== undefined)&& 
                    <div className='input-group-append-text'>
                        <span>
                          <img src="images/tick-mark.svg" alt="tick icon for validation" className='validation-tickmark' />
                        </span>
                    </div>
                  }
                </div>
                {/* user name error message */}
                {allDetails[this.props.field_api_name] !== ''&&
                  <p className="error-message"><span class="app-error-message" style={{ color: 'Red' }}>{allDetails[this.props.field_api_name]}</span> </p>
                }

              </div>
            </div>

          }
          {this.props.field_question === '' &&  this.props.field_api_name != "Total_Expenditure__c" &&
            <div className={this.classNameForDivElement(this.props)}>
              <div class="form-group">
                <span></span>

                <input type={this.state.inputType} name={this.props.field_api_name} value="" readOnly={true} style={{ display: "none", opacity: 0, position: "absolute" }} autoComplete="off" />

                <input type={this.state.inputType} min= {minNumber} max={maxNumber} onWheel={(event) => { event.target.blur() }} className={`form-control  ${this.props.field_type === 'Currency' && this.state.value !== '' ? "custom-cat-displays" : ""}`} id="" onFocus={this.onFocus} name={this.props.field_api_name} aria-describedby="email" autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false"
                  value={this.state.value ? this.state.value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",") : this.state.value} placeholder={this.props.field_placeholder} onChange={this.handleChange} onClick = {(e) => analyticsClickEvent(this.props.field_label,'')} onPaste={(e) => { this.onlyAcceptInputs(e) }} />
                <p className="error-message">{this.state.errors[this.props.field_api_name] !== '' && this.state.showValidation === 'yes' &&
                  <span class="app-error-message" style={{ color: 'Red' }}>{this.state.errors[this.props.field_api_name]}</span>
                }
                </p>
              </div>
            </div>
          }
        {/* Disabled the Total Expenditure 3244-ID */}
        


        </React.Fragment>
      );
    } else {
      return (
        <></>
      )
    }

  }
}



export const mapStateToProps = (state) => {
  return {
    firstSectionSteps: state.dataStore.firstSectionSteps,
    applicationValidation: state.dataStore.applicationValidation,
    showAppValidations: state.dataStore.showAppValidations,
    emails: state.dataStore.emails,
    confirmemail: state.dataStore.confirmemail,
    saveApplicationData: state.dataStore.saveApplicationData,
    phoneNumbers: state.dataStore.phoneNumbers,
    employmentStatus: state.dataStore.employmentStatus,
    amountRequested: state.dataStore.amountRequested,
    threashold:state.dataStore.threashold,
    questionValueSave:state.dataStore.questionValueSave,
    prepopulateStatus:state.dataStore.prepopulateStatus,
    userDetailsPopulateStatus: state.dataStore.userDetailsPopulateStatus,
    firstNameFlag: state.dataStore.firstNameFlag,
		lastNameFlag: state.dataStore.lastNameFlag,
		emailFlag: state.dataStore.emailFlag,
		mobileNumberFlag: state.dataStore.mobileNumberFlag,
    isMobileVerified: state.dataStore.isMobileVerified,
    isEmailVerified: state.dataStore.isEmailVerified,
    isChangeMobile: state.dataStore.isChangeMobile,
    isChangeEmail: state.dataStore.isChangeEmail,
    secondSectionSteps: state.dataStore.secondSectionSteps,
    fourSectionSteps: state.dataStore.fourSectionSteps,
  };
};


export const mapDispatchToProps = (dispatch) => {
  return {
    appvalidations: (validations) => {
      return dispatch({
        type: "APP_VALIDATIONS",
        value: validations
      });
    },
    firstSectionValidationUpdate: (validations) => {
      return dispatch({
        type: "FIRST_SECTION_STEPS",
        value: validations
      });
    },
    secondSectionValidationUpdate: (validations) => {
      return dispatch({
        type: "SECOND_SECTION_STEPS",
        value: validations
      });
    },
    thirdSectionValidationUpdate: (validations) => {
      return dispatch({
        type: "THIRD_SECTION_STEPS",
        value: validations
      });
    },
    fourSectionValidationUpdate: (validations) => {
      return dispatch({
        type: "FOUR_SECTION_STEPS",
        value: validations
      });
    },
    updateMobileChange: (validations) => {
      return dispatch({
        type: 'SET_MOBILE_CHANGE',
        value: validations
      });
    },
    updateEmailChange: (validations) => {
      return dispatch({
        type: 'SET_EMAIL_CHANGE',
        value: validations
      });
    },
    showappValidation: (validations) => {
      return dispatch({
        type: "SHOW_APP_VALIDATIONS",
        value: validations
      });
    },
    email: (validations) => {
      return dispatch({
        type: 'EMAIL',
        value: validations
      });
    },
    confirmemails: (validations) => {
      return dispatch({
        type: 'CONFIRMEMAIL',
        value: validations
      });
    },
    saveApplicationDatas: (data) => {
      return dispatch({
        type: 'SAVEDATA',
        value: data
      });
    },
    removeApplicationData: (data) => {
      return dispatch({
        type: 'REMOVEDATA',
        value: data
      });
    },
    getPhoneNumber: (validations) => {
      return dispatch({
        type: 'PHONENO',
        value: validations
      });
    },
    getAmountRequested: (validations) => {
      return dispatch({
        type: 'AMOUNTREQUESTED',
        value: validations
      });
    },
    threasholdFunc: (validations) => {
      return dispatch({
        type: 'THREADSHOLD',
        value: validations
      });
    },
    questionSave:(data)=>{
      return dispatch({
        type: 'QUESTIONSAVE',
        value: data
    });
    },

  }
}

Input.propTypes = {
  firstSectionSteps: PropTypes.array,
  showAppValidations: PropTypes.string,
  field_api_name: PropTypes.string,
  stage: PropTypes.string,
  threshold: PropTypes.number,
  field_fieldinfo: PropTypes.string,
  priority: PropTypes.number,
  field_api_validations: PropTypes.array,
  questionSave: PropTypes.func,
  field_type: PropTypes.string,
  field_placeholder: PropTypes.string,
  saveApplicationData: PropTypes.array,
  applicationValidation: PropTypes.array,
  initialLoad: PropTypes.bool,
  employmentStatus: PropTypes.bool,
  onBlurEvent: PropTypes.func,
  threasholdFunc: PropTypes.func,
  fieldChange: PropTypes.func,
  appvalidations: PropTypes.func,
  getAmountRequested: PropTypes.func,
  editable: PropTypes.bool,
  prepopulateStatus: PropTypes.bool,
  firstNameFlag: PropTypes.bool,
  lastNameFlag: PropTypes.bool,
  emailFlag: PropTypes.bool,
  mobileNumberFlag: PropTypes.bool,
  firstSectionValidationUpdate: PropTypes.func,
  secondSectionValidationUpdate: PropTypes.func,
  fourSectionValidationUpdate: PropTypes.func,
  saveApplicationDatas: PropTypes.func,
  field_label: PropTypes.string,
  isChangeEmail: PropTypes.bool,
  isChangeMobile: PropTypes.bool,
  field_value: PropTypes.string,
  flxQuestionMoreInfo: PropTypes.string,
  userDetailsPopulateStatus: PropTypes.bool,
  getPhoneNumber: PropTypes.func,
  isMobileVerified: PropTypes.bool,
  isEmailVerified: PropTypes.bool,
  prepopulateCondition: PropTypes.bool,
  email: PropTypes.func,
  confirmemails: PropTypes.func,
  field_question: PropTypes.string,
  goBackAmendClicked:PropTypes.bool,
  onBlurInputFiled:PropTypes.bool,
  disableMobileNumberField: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(Input)
